import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useUser } from '@bluheadless/ui-logic/src/providers/user'
import NoRoutePageComponent from '@bluheadless/ui/src/page-components/no-route/no-route'
import Head from 'next/head'
import { useEffect } from 'react'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'

const NoRoute = () => {
	const { pageViewDetails } = useTrackingsActions()
	const { entityType: pageContext, __bhEntityId: pageId } = usePageContext()
	const [{ isLoggedIn }] = useUser()
	const { locale } = useConfig()

	useEffect(() => {
		pageViewDetails({ pageType: 'not-found-page', params: { isLoggedIn, locale }, pageContext, pageId })
	}, [isLoggedIn, pageViewDetails, locale, pageContext, pageId])

	return (
		<>
			<Head>
				<meta name="robots" content="noindex,nofollow" />
			</Head>
			<NoRoutePageComponent />
		</>
	)
}

export default NoRoute

NoRoute.layout = { type: 'boxed', cssClass: 'not-found-page' }
